import React, {
  Fragment,
  lazy,
  Suspense,
  useContext,
  useRef,
  useState,
} from 'react'
import {
  ELEMENT_TYPES,
  GROUP_CHILD_ELEMENT_TYPES,
  INTERACTIVE_ELEMENT_TYPES,
  VIDEO_ELEMENT_TYPES,
  getElementRow,
  getElementSlideType,
  selectSidebarElement,
  unSelectSidebarElements,
} from '../../helpers/elementHelper'
import { setNode } from '../../helpers/nodeHelper'
import { useReactFlow } from 'reactflow'
import ElementCardDropdown from './ElementCardDropdown'
import TransparentInput from './TransparentInput'
import { capitalized } from '../../../../utils/helpers/javascript'
import {
  FaArrowRight,
  FaExternalLinkAlt,
  FaFlagCheckered,
  FaPlus,
  FaRandom,
} from 'react-icons/fa'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import useSelectedNode from '../../hooks/useSelectedNode'
import AnswerPointsDropdown from './AnswerPointsDropdown'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import {
  moveCameraToPosition,
  unSelectCollistionBox,
} from '../../../Editor/helpers/controls'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import ElementLetterLabel from '../FlowEditor/elements/ElementLetterLabel'
import SceneToNumberLabel from '../FlowEditor/elements/SceneToNumberLabel'
import PointLabel from '../FlowEditor/elements/PointLabel'
import { useLazyQuery } from '@apollo/client'
import { getMediaByScenarioIdQuery } from '../../../../apollo/query/media'
import ElementSettings from './ElementSettings'
import ElementInput from './ElementInput'

const RichMediaTypeButtons = lazy(() => import('./RichMediaTypeButtons'))

const renderRichMediaTypeButtons = (
  element,
  hidden,
  setLoading,
  addNewSlide
) => {
  return (
    <Suspense>
      <RichMediaTypeButtons
        hidden={hidden}
        element={element}
        setLoading={setLoading}
        addNewSlide={addNewSlide}
      />
    </Suspense>
  )
}

const moveCameraToElement = (domElement) => {
  const targetRotation = domElement.parentNode.getAttribute('rotation')
  moveCameraToPosition(targetRotation.x, targetRotation.y)
}

const selectCollisionBox = (element) => {
  const id = `${element.kind}-${element.id}`
  const collisionBox = document.querySelector(`a-collision-box#${id}`)
  unSelectSidebarElements()
  unSelectCollistionBox()

  if (collisionBox) {
    setTimeout(() => {
      collisionBox.emit('intersect')
      collisionBox.emit('is-selected', { isSelected: true, scroll: false })
      moveCameraToElement(collisionBox)
    }, 0)
  } else selectSidebarElement(`element-${element.kind}-${element.id}--card`)
}

const Card = ({
  className = '',
  element,
  last,
  parentIndex,
  childIndex,
  parentElement,
  deleteDisabled,
  deleteDisabledText = '',
  setSelectedMediumId,
  setLoading,
}) => {
  const [showDropdownButton, setShowDropdownButton] = useState(false)
  const { updateElementSlideDetails, updateElement } = useAddUpdateElement()
  const { scenario } = useContext(ScenarioEditorContext)
  const reactFlow = useReactFlow()
  const { getNodes } = reactFlow
  const showVideoEditor = useShowVideoEditor()

  const elRow = getElementRow(element)
  if (!elRow) return <></>

  const hideDropdownButton = () => setShowDropdownButton(false)

  const handleChange = ({ target: { value, name } }) => {
    if (element[name] !== value)
      updateElement({
        variables: {
          ...element,
          [name]: value,
        },
      })
  }

  const renderDescription = () => {
    if (element.kind === 'SLIDE' && getElementSlideType(element) === 'TEXT')
      return (
        <div className="pb-1 -ml-1">
          <TransparentInput
            id={`element-desc-${element.id}`}
            type="htmlEditor"
            name="description"
            onConfirm={({ target: { value } }) => {
              updateElementSlideDetails({
                ...element,
                description: value,
              })
            }}
            value={element.description ?? ''}
            placeholder="Add text on the slide..."
            maxHeight={75}
            minHeight={58}
            focusedMinHeight={102}
          />
        </div>
      )

    return <></>
  }

  const renderLabels = () => {
    const renderLetterLabel = () => {
      if (!INTERACTIVE_ELEMENT_TYPES.includes(element.kind)) return <></>
      return (
        <ElementLetterLabel
          className="mr-0-5 mt-0-5"
          index={parentIndex + (childIndex ?? 0) + 1}
        />
      )
    }

    const renderNumberLabel = () => {
      if (!element.linkToId) return <></>
      const linkedNode = getNodes().find((n) => n.id === element.linkToId)
      if (!linkedNode) return <></>
      return (
        <SceneToNumberLabel linkedNode={linkedNode} className="mt-0-5 mr-0-5" />
      )
    }

    const renderPointLabel = () => (
      <PointLabel points={element.points} className="mr-0-5 mt-0-5" />
    )

    const renderRandomizerLabel = () => {
      if (!element.randomizedLinkToIds.length) return <></>
      return (
        <>
          <span
            className={`o-label o-label--balanced text-white mr-0-5 mt-0-5 text-small`}>
            <FaRandom />
            &nbsp;
            {element.randomizedLinkToIds.length}
          </span>
          {element.randomizedLinkToIds.map((sceneId, index) => {
            if (!sceneId) return <Fragment key={`${element.id}--${index}`} />
            const linkedNode = getNodes().find((n) => n.id === sceneId)
            if (!linkedNode)
              return <Fragment key={`${element.id}-${sceneId}-${index}`} />
            return (
              <span
                key={`${element.id}-${sceneId}-${index}`}
                className="o-label o-label--stable-dark text-white mr-0-5 mt-0-5 text-small">
                <FaArrowRight />
                &nbsp;
                {linkedNode.data.number}
              </span>
            )
          })}
        </>
      )
    }

    const renderEndingLabel = () => {
      if (element.linkToEnding)
        return (
          <span className="o-label o-label--dark-light flex-container align-middle mr-0-5 mt-0-5 text-small">
            <FaFlagCheckered className="mr-0-5" />
            Ending
          </span>
        )
      return <></>
    }
    return (
      <span className="ml-1 flex-container flex-wrap">
        {renderLetterLabel()}
        {renderNumberLabel()}
        {renderPointLabel()}
        {renderRandomizerLabel()}
        {renderEndingLabel()}
      </span>
    )
  }

  const getSaveButton = () => {
    const save = () => {
      const videoElement = document.querySelector(
        `#${element.kind}-${element.id}`
      )
      const rotation = videoElement.parentNode.getAttribute('rotation')

      updateElement({
        variables: {
          ...element,
          anchorX: -rotation.y, // V2
          anchorY: rotation.x, // V2
        },
      })
    }
    return (
      <button
        id={`element-${element.kind}-${element.id}--save-button`}
        hidden
        onClick={save}></button>
    )
  }

  const isClickable =
    !element.groupUuid &&
    showVideoEditor &&
    (VIDEO_ELEMENT_TYPES.includes(element.kind) || element.kind === 'TIMER')

  const isParent = !!element.groupUuid

  const handleClick = (e) => {
    if (!isClickable || e.currentTarget.classList.contains('active'))
      return false
    selectCollisionBox(parentElement ?? element)
  }

  const renderTitle = () => {
    if (element.kind === 'SLIDE')
      return `${capitalized(getElementSlideType(element))} ${elRow.title.toLowerCase()}`

    return capitalized(elRow.title)
  }

  const renderThumbnail = () => {
    if (!element.flatAttachment) return null

    if (
      element.kind === 'SLIDE' &&
      ['VIDEO', 'IMAGE'].includes(getElementSlideType(element))
    ) {
      const previewUrl = element.flatAttachment.previewUrl
      return (
        <div
          data-open="medium-modal"
          onClick={async () => {
            setSelectedMediumId(element.flatAttachment?.mediumId)
          }}
          className="border-radius mb-1 ml-1 border-stable-light cursor-pointer element-card--thumbnail"
          style={{
            width: '74px',
            height: '74px',
            minWidth: '74px',
          }}>
          <img
            className="w-100 h-100"
            src={previewUrl}
            style={{
              objectFit: 'cover',
            }}
          />
          <FaExternalLinkAlt className="icon" />
        </div>
      )
    }

    return null
  }

  const renderHeader = () => {
    // There is no body text, no image and no video. Show ‘Select slide type’ and the 3 buttons [Text], [Image] and [Video].
    if (element.kind === 'SLIDE' && getElementSlideType(element) === 'DRAFT')
      return (
        <div className="flex-container align-middle">
          <label className="mr-1">Select slide type</label>
          {renderRichMediaTypeButtons(element, false, setLoading, false)}
        </div>
      )

    return (
      <div className="flex-container align-top">
        <div
          id={`element-${element.kind}-${element.id}`}
          className="flex-container align-middle mt-0-5">
          {elRow.icon}
          <h4 className="text-normal text-bold mb-0 ml-1">{renderTitle()}</h4>
        </div>
        {renderLabels()}
      </div>
    )
  }

  const showStarButton =
    scenario.scoringSystem.kind !== 'DISABLED' &&
    INTERACTIVE_ELEMENT_TYPES.includes(element.kind)

  return (
    <div
      id={
        isParent
          ? `element-${element.kind}-${element.groupUuid}-${element.id}--card`
          : `element-${element.kind}-${element.id}--card`
      }
      className={`${className}
        ${last ? 'border-0' : ''} 
        ${isParent ? 'border-light-bottom' : 'border-light border-radius mb-2'}
        ${isClickable ? 'cursor-pointer' : ''}
        flex-container align-top align-justify pl-2 pr-1-5 pt-1-5
        element-card
        `}
      onMouseEnter={setShowDropdownButton}
      onMouseLeave={hideDropdownButton}
      onClick={handleClick}
      style={{ transition: 'none' }}>
      <div
        className="w-100 flex-container flex-dir-column align-justify"
        style={{ minHeight: '74px' }}>
        <header className="text-small flex-container align-middle align-justify mb-0-5">
          {renderHeader()}
        </header>
        <ElementInput element={element} handleChange={handleChange} />
        {renderDescription()}
        {!isParent && <ElementSettings element={element} />}
        {getSaveButton()}
      </div>
      <div className="flex-container">
        <div style={{ position: 'relative' }}>
          <div
            style={{
              visibility: showDropdownButton ? 'visible' : 'hidden',
              position: 'absolute',
              right: 0,
              top: 0,
            }}>
            {(!isParent ||
              GROUP_CHILD_ELEMENT_TYPES.includes(element.kind)) && (
              <ButtonGroup className="ml-0-5">
                {showStarButton && <AnswerPointsDropdown element={element} />}
                <ElementCardDropdown
                  element={element}
                  onDeleteModalOpen={hideDropdownButton}
                  deleteDisabled={deleteDisabled}
                  deleteDisabledText={deleteDisabledText}
                  last={!showStarButton}
                />
              </ButtonGroup>
            )}
          </div>
        </div>
        {renderThumbnail()}
      </div>
    </div>
  )
}

const ElementCard = ({
  element: parentElement,
  answerElements,
  index: parentIndex,
  loading,
  setLoading,
  setSelectedMediumId,
}) => {
  const reactFlow = useReactFlow()
  const selectedNode = useSelectedNode()
  const { addElement, addSlide } = useAddUpdateElement()
  const [showDropdownButton, setShowDropdownButton] = useState(false)
  const showVideoEditor = useShowVideoEditor()

  const singleElement = !parentElement.groupUuid

  if (singleElement)
    return (
      <Card
        className="c-video__editor--elements--card"
        element={parentElement}
        parentIndex={parentIndex}
        setLoading={setLoading}
      />
    )

  const groupElRow = ELEMENT_TYPES.find(
    (el) => el.type === parentElement.groupKind
  )
  if (!groupElRow) return <></>

  const hideDropdownButton = () => setShowDropdownButton(false)

  const handleAddAnswer = () => {
    setLoading(true)
    addElement(
      {
        variables: {
          sceneId: selectedNode.id,
          groupKind: parentElement.groupKind,
          groupUuid: parentElement.groupUuid,
          kind: 'ANSWER',
        },
      },
      selectedNode
    ).then(({ data }) => {
      const newNode = {
        ...selectedNode,
        selected: true,
        data: {
          ...selectedNode.data,
          elements: [...selectedNode.data?.elements, data.addElement.element],
        },
      }
      setNode(reactFlow, newNode)
      setLoading(false)
    })
  }

  const renderNewAnswerButton = () => {
    if (parentElement.groupKind === 'MPC')
      return (
        <button
          className="o-button o-button--light o-button--square-small flex-container align-middle mb-0 pb-0-5 pt-0-5 pr-1 pl-1 mr-0-5 w-auto"
          onClick={handleAddAnswer}
          disabled={loading}
          hidden={!showDropdownButton}>
          <FaPlus className="mr-0-5 text-small" />
          <span className="text-bold text-normal">New answer</span>
        </button>
      )

    if (parentElement.groupKind === 'MEDIAPANEL')
      return (
        <button
          hidden={!showDropdownButton}
          className="o-button o-button--light o-button--square-small flex-container align-middle mb-0 pb-0-5 pt-0-5 pr-1 pl-1 mr-0-5 w-auto"
          onClick={async () => {
            setLoading(true)
            await addSlide(parentElement)
            setLoading(false)
          }}>
          <FaPlus className="mr-0-5 text-small" />
          <span className="text-bold text-normal">New slide</span>
        </button>
      )

    return <></>
  }

  const isClickable = showVideoEditor && parentElement.kind !== 'TRANSITION'

  const handleClick = (e) => {
    if (!isClickable || e.currentTarget.classList.contains('active'))
      return false
    selectCollisionBox(parentElement)
  }

  return (
    <div
      id={`element-${parentElement.kind}-${parentElement.id}--card`}
      className={`border-light border-radius mb-2 c-video__editor--elements--card 
        ${isClickable ? 'cursor-pointer' : ''}`}
      onMouseEnter={setShowDropdownButton}
      onMouseLeave={hideDropdownButton}
      onClick={handleClick}>
      <header className="flex-container align-middle align-justify text-stable-dark p-1 pl-1-5 pr-1-5 border-light-bottom">
        <div className="flex-container align-middle">
          {groupElRow.icon}
          <h4 className="text-normal text-bold mb-0 ml-1">
            {capitalized(groupElRow.title)}
          </h4>
        </div>
        <div className="flex-container">
          {renderNewAnswerButton()}
          <span
            style={{ visibility: showDropdownButton ? 'visible' : 'hidden' }}>
            <ButtonGroup>
              <ElementCardDropdown
                element={parentElement}
                onDeleteModalOpen={hideDropdownButton}
                last={false}
                parentElement={parentElement}
              />
            </ButtonGroup>
          </span>
        </div>
      </header>
      <Card
        element={parentElement}
        parentIndex={parentIndex}
        setLoading={setLoading}
      />
      {answerElements.map((el, index) => (
        <Card
          key={el.id}
          element={el}
          last={index === answerElements.length - 1}
          parentIndex={parentIndex}
          childIndex={index}
          parentElement={parentElement}
          deleteDisabled={
            answerElements.length === 1 &&
            ['MPC', 'MEDIAPANEL'].includes(parentElement.groupKind)
          }
          deleteDisabledText={groupElRow.deleteDisabledText}
          setSelectedMediumId={setSelectedMediumId}
          setLoading={setLoading}
        />
      ))}
      <ElementSettings element={parentElement} labelElements={answerElements} />
    </div>
  )
}

export default ElementCard
